.header {
  background-image: url('../assets/img_background.png');
  background-position: center;
  background-size: cover;
  height: 100vh;
  min-height: 720px;
  position: relative;
}

.logo {
  left: 50%;
  position: absolute;
  top: 25px;
  transform: translateX(-50%);
}

.menu {
  display: flex;
  height: 50px;
  justify-content: flex-end;
  padding: 20px 15px;

  .menu-item {
    align-content: center;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      @extend .hover;
    }

    .link {
      box-sizing: border-box;
      padding: 30px 10px;
    }
  }

  .contact {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 15px;
    text-transform: uppercase;

    .contact-link {
      padding: 30px;
    }
  }

  .language {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 15px;

    .active {
      font-weight: bold;
    }
  }
}

.cont-text {
  display: flex;
  flex-direction: column;
  margin: 120px auto 0;
  max-width: 780px;

  .text-title {
    font-family: $font-secondary;
    font-size: 75px;
    font-weight: bold;
    line-height: 75px;
    margin-bottom: 30px;
    text-align: center;
  }

  .text-subtitle {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
}

.scroll {
  bottom: 170px;
  display: flex;
  left: 30px;
  position: absolute;
  z-index: 1;
}

.scroll-arrow-text {
  color: $color-text;
  font-size: 12px;
  font-weight: bold;
  line-height: 45px;
  transform: rotate(270deg);
}

.scroll-arrow {
  animation: MoveUpDown 2.5s linear infinite;
  height: 45px;
}

@keyframes MoveUpDown {
  75% {
    transform: translate(0, -20%);
  }

  25% {
    transform: translate(0, 20%);
  }
}
