.modal-dialog {
  background: $color-background-shadow;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  z-index: 2;

  &:target {
    opacity: 1;
    pointer-events: auto;
  }

  .modal {
    background: $color-background;
    background-image: url('../assets/img_modal_bg.png');
    border-radius: 10px;
    margin: 10% auto;
    max-width: 650px;
    padding: 5px 20px 15px;
    position: relative;

    .cont-modal {
      margin: 30px 40px;
    }

    .text-modal {
      color: $color-text-content;
      font-size: 16px;
      line-height: 20px;
      margin: 30px 0;
      text-align: justify;
    }
  }

  .close {
    align-items: center;
    background: $color-background-dark;
    border-radius: 50%;
    color: $color-text;
    display: flex;
    font-size: 22px;
    font-weight: bold;
    height: 30px;
    justify-content: center;
    line-height: 25px;
    position: absolute;
    right: 25px;
    text-align: center;
    text-decoration: none;
    top: 30px;
    width: 30px;

    &:hover {
      @extend .hover;
    }
  }
}
