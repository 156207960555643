body {
  background-color: $color-background;
  background-image: url('../assets/img_bg_map.png');
  background-position: 100% 45%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  color: $color-text;
  font-family: $font-principal;
  margin: 20px;
}

.section {

  .text-title {
    color: $color-title-section;
    font-family: $font-secondary;
    font-size: 65px;
    font-weight: bold;
    line-height: 65px;
  }

  .text-subtitle {
    color: $color-text-section;
    font-size: 20px;
    line-height: 25px;
    margin: 15px 0 70px;
  }
}

.hover {
  background: $color-background-hover;
  border: 1px solid $color-text-hover;
  color: $color-text-hover;
  cursor: pointer;
  opacity: 0.8;
}
