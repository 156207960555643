
$color-background: #FFF;
$color-background-navbar: #00195C;
$color-background-hover: #006FCB;
$color-text-hover: #72D3FF;
$color-text: #FFF;
$color-title-section: #00195C;
$color-text-section: #4A4A4A;
$color-background-section: #001D96;
$color-button-border: #80B942;
$color-section-border: rgba(255, 255, 255, 0.24);
$color-text-content: #4A4A4A;
$color-background-shadow: rgba(0, 0, 0, 0.8);
$color-background-dark: #000;
$color-red: #F00;
$color-red-with-opacity: rgba($color-red, 0.75);
$color-black: #000;
