.footer {
  background-color: $color-background-section;
  position: relative;
  z-index: 2;

  .contact {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 390px;
    margin: 0 auto;
    padding: 100px 0 80px;

    .text-title {
      color: $color-text;
      font-family: $font-secondary;
      font-size: 45px;
      font-weight: bold;
      line-height: 0;
      margin-bottom: 50px;
      text-align: center;
    }

    .button {
      background: transparent;
      border: 2px solid $color-button-border;
      box-sizing: border-box;
      color: $color-text;
      font-family: $font-principal;
      font-size: 16px;
      font-weight: bold;
      height: 55px;
      line-height: 15px;
      margin-top: 20px;
      width: 100%;

      &:hover {
        @extend .hover;
      }
    }
  }

  .footer-info {
    align-items: center;
    border-top: 2px solid $color-section-border;
    display: flex;
    height: 90px;
    justify-content: space-between;
    margin: 0 25px;

    .text-reserved {
      font-size: 12px;
      line-height: 15px;
      margin-left: 65px;
    }

    .links {
      align-items: center;
      display: flex;
      font-size: 12px;
      line-height: 15px;

      .link {
        align-items: center;
        border: 1px solid transparent;
        display: inline-flex;
        margin: 0 50px;
        padding: 10px 5px;

        &:hover {
          @extend .hover;
        }
      }

      .legal {
        color: $color-text;
        text-decoration: none;
      }

      .text-to-top {
        font-size: 10px;
        font-weight: bold;
        margin-top: 5px;
        transform: rotate(270deg);
      }
    }

    .icon {
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }

    .ic-legal {
      background: url('../assets/ic_footer_legal.png');
    }

    .ic-email {
      background: url('../assets/ic_footer_email.png');
    }

    .ic-top {
      background: url('../assets/ic_arrow_up.png');
      height: 40px;
      margin-right: 0;
    }
  }

  .contact.contact-form-container {
    min-height: 400px;
    margin: 0 auto;
    max-width: 500px;

    .contact-form {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .contact-form-title {
      line-height: 45px;

      &.contact-form-label {
        font-size: 32px;
        line-height: 0;
      }

      &:not(.hidden) {
        font-size: 32px;
        margin-bottom: 50px;
      }
    }

    .contact-form-initial-message {
      max-width: 230px;
      min-width: 210px;

      .contact-form-title {
        font-size: 42px;
      }
    }

    .contact-form-text-area {
      height: 100%;
      width: 100%;
    }

    .hidden {
      border: 0;
      height: 0;
      margin: 0;
      opacity: 0;
      transition: opacity 0.25s, height 0 ease 0.5s;
      visibility: hidden;
    }

    .contact-form-input:not(.button-1) {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid $color-text;
      color: $color-text;
      font-family: $font-principal;
      font-size: 21px;
      font-weight: bold;
      line-height: 60px;
      max-width: 450px;
      outline: 0;
      text-align: center;
      width: 100%;

      &.email-input {
        max-width: 700px;
      }

      &.message-input {
        box-sizing: border-box;
        font-size: 16px;
        height: 100%;
        line-height: 25px;
        overflow: hidden;
        padding-bottom: 110px;
        resize: none;
        text-align: left;
        width: 100%;
      }

      &.error {
        border-bottom: 1px solid $color-red-with-opacity;
      }

      &::placeholder {
        color: $color-text;
        opacity: 0.5;
      }
    }

    .contact-form-paper-plane-container.hidden {
      width: 0;
    }

    .contact-form-paper-plane {
      height: 39px;
    }

    .contact-form-arrows-container {
      align-items: center;
      align-self: center;
      display: flex;
    }

    .contact-form-arrow {
      color: $color-text;
      font-size: 14px;
      z-index: -1;

      &.hidden {
        width: 0;
      }

      &:hover {
        color: $color-text;
      }

      &:not(.hidden) {
        padding: 0 10px;
        z-index: 2;
      }
    }

    .contact-form-error {
      color: $color-red-with-opacity;
      font-weight: bold;
      min-height: 34px;
      padding: 10px;

      &.hidden {
        min-height: 34px;
      }
    }
  }
}
