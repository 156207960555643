.market {
  padding: 100px 75px;
  position: relative;
  z-index: 2;

  .text-subtitle {
    max-width: 480px;
  }

  .qualities {
    display: flex;
    justify-content: center;

    .qualities-item {
      margin: 0 25px;
      min-width: 160px;
      width: 33%;
    }
  }

  .qualities-title {
    color: $color-title-section;
    font-family: $font-secondary;
    font-size: 35px;
    font-weight: bold;
    line-height: 45px;
  }

  .qualities-description {
    color: $color-text-section;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
}
