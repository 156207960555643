@import 'https://fonts.googleapis.com/css?family=Open+Sans|Rajdhani:400,700';
@import 'variables/colors';
@import 'variables/fonts';
@import 'normalize';
@import 'base';
@import 'firstSection';
@import 'marketSection';
@import 'footer';
@import 'modal';
@import 'responsive';
