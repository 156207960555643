@media screen and (max-width: 699px) {
  body {
    background: none;
    background-color: $color-background;
    margin: 0;
  }

  .header {
    background-position: inherit;
    background-repeat: no-repeat;
    height: auto;
    margin-bottom: 30px;
    min-height: auto;
    padding-bottom: 120px;
    text-align: center;
  }

  .logo {
    left: 0;
    margin-bottom: 45px;
    position: static;
    top: 0;
    transform: translateX(0);
  }

  .menu {
    background-color: $color-background-navbar;
    margin-bottom: 20px;
    padding: 0 15px;

    .menu-item {

      .link {
        padding: 18px 10px;
      }
    }

    .contact {
      margin-right: auto;

      .contact-link {
        padding: 18px 10px;
      }
    }
  }

  .cont-text {
    margin: 0 25px;

    .text-title {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 10px;
    }

    .text-subtitle {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .scroll {
    bottom: 35px;
    left: 20px;
  }

  .section {
    margin: 0 20px 50px;

    .text-title {
      font-size: 30px;
      line-height: 40px;
    }

    .text-subtitle {
      font-size: 12px;
      line-height: 15px;
      margin: 10px 0 40px;
    }
  }

  .market {
    padding: 0;

    .qualities {
      display: flex;
      flex-direction: column;

      .qualities-item {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
      }
    }

    .qualities-title {
      font-size: 20px;
      line-height: 25px;
    }

    .qualities-description {
      font-size: 10px;
      line-height: 15px;
      margin: 5px 0 30px;
    }
  }

  .footer {
    margin: 0;

    .contact {
      height: 290px;
      margin: 0 30px;
      padding: 70px 0 20px;

      .text-title {
        font-size: 20px;
        line-height: 25px;
      }

      .button {
        font-size: 12px;
        height: 45px;
        width: 100%;
      }
    }

    .contact.contact-form-container {
      min-height: 300px;
      padding: 70px 20px 20px;

      .contact-form-title {

        &.contact-form-label {
          font-size: 21px;
        }

        &:not(.hidden) {
          font-size: 21px;
          margin-bottom: 30px;
        }
      }

      .contact-form-initial-message {
        min-width: 190px;

        .contact-form-title {
          font-size: 21px;
          margin-bottom: 30px;
        }
      }

      .contact-form-input:not(.button-1) {
        font-size: 16px;
        line-height: 45px;

        &.message-input {
          font-size: 16px;
          line-height: 20px;
          padding: 0 0 100px 20px;
        }
      }
    }

    .footer-info {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: space-between;
      padding: 20px 0 30px;

      .text-reserved {
        font-size: 12px;
        line-height: 15px;
        margin: 0;
        order: 2;
      }

      .links {
        display: block;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 25px;
        position: relative;

        .link {
          display: inline-flex;
          margin: 0;
          padding: 10px 5px;
        }

        .legal {
          width: 50%;
        }

        .email {
          width: 50%;
        }

        .to-top {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }

  .modal-dialog {
    margin: 0;

    .modal {

      .text-modal {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .header {
    background-image: url('../assets/img_background@2x.png.png');
    min-height: 1050px;
  }

  .cont-text {
    margin: 230px auto 0;
    max-width: 800px;
  }

  .scroll {
    bottom: 260px;
  }
}

@media screen and (min-width: 1600px) {
  .header {
    min-height: 1250px;
  }

  .scroll {
    bottom: 310px;
  }
}
